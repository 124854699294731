<template>
  <v-container fluid>
    <bread-crumbs />
    <customer-details-info @redirect="redirectBack" />

    <v-card>
      <v-row class="mt-2">
        <v-col
          cols="12"
          class="pb-0"
        >
          <!-- @change="tabChanged(tab)" -->
          <v-tabs v-model="tab">
            <v-tab
              v-for="tabItem in items"
              :key="tabItem.tab"
            >
              {{ tabItem.tab }}
            </v-tab>
            <v-tab-item
              v-if="isPermission('vehicle-telematics-detail.dashboard')"
            >
              <vehicle-telematics-chart />
            </v-tab-item>
            <!--Tab 1 : Message tab  -->
            <v-tab-item>
              <messages-tab />
            </v-tab-item>
            <!--Tab 2 : Plugins tab  -->
            <v-tab-item v-if="isPermission('vehicle-charge-sessions.list')">
              <charge-session-tab />
            </v-tab-item>
          </v-tabs>
          <!-- <messages-tab v-if="tab == 0" />
          <charge-session-tab v-else-if="tab == 1" /> -->
        </v-col>
      </v-row>
      <!-- v-if="$admin.can('users.edit')" -->
      <edit-user
        :is-visible-dialog="editUserModal"
        :read-only="true"
        :toggle-edit="toggleUserModal"
        :oem-customer="selectedUser"
      />

      <!-- v-if="$admin.can('vehicles.edit')" -->
      <edit-vehicle
        :is-visible-dialog="editVehicleModal"
        :read-only="true"
        :toggle-edit="toggleVehicleModal"
        :vehicle="selectedUser"
      />
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {
    BreadCrumbs: () => import("@/layouts/core/BreadCrumbs.vue"),
    CustomerDetailsInfo: () =>
      import("@/components/sections/CustomerDetailsInfo.vue"),
    VehicleTelematicsChart: () => import("../tabs/VehicleTelematicsChart.vue"),
    MessagesTab: () => import("../tabs/MessagesTab1.vue"),
    ChargeSessionTab: () => import("../tabs/ChargeSessionTab.vue"),
    EditUser: () =>
      import("@/views/modules/customer/dialogs/EditOemCustomer.vue"),
    EditVehicle: () =>
      import("@/views/modules/vehicles/dialogs/EditVehicleTabs.vue"),
  },

  data() {
    return {
      tab: 0,
      isInfoDetailShow: true,
      selectedUser: {},
      editUserModal: false,
      editVehicleModal: false,
      options: {},
    };
  },
  computed: {
    ...mapGetters({
      vehicleDetails: "vehicles/getVehicle",
      vehicleDetailsLoading: "vehicles/isLoadingVehicle",
    }),
    items() {
      let tab = [{ tab: this.$t("messages"), content: "messages-tab" }];
      if (this.$admin.hasAccessTo("vehicle-telematics-detail.dashboard")) {
        tab.unshift({
          tab: this.$t("dashboard"),
          content: "dashboard",
        });
      }
      // if (this.$admin.hasAccessTo("vehicle-charge-sessions.list")) {
      //   tab.push({
      //     tab: this.$t("charge_session"),
      //     content: "charge-session-tab",
      //   });
      // }
      return tab;
    },
  },

  async mounted() {
    const id = this.$route.params.imei;
    this.options.imei = id;

    let imei = this.$route.params.imei;
    await this.$store.dispatch("vehicles/show", imei);
  },

  methods: {
    isPermission(val) {
      return this.$admin.can(val);
    },
    redirectBack() {
      this.$store.commit("telematicDetails/CLEAR_TELEMATICDETAILS");

      return this.$router.push({
        name: "vehicle_telematics",
        // query: { tenant_id: this.$route.query.tenant_id },
        query: this.$route.query,
      });
    },
    InfoDetailToggle() {
      this.isInfoDetailShow = !this.isInfoDetailShow;
    },
    showUserModal(item) {
      this.selectedUser = { ...item.customer };
      this.toggleUserModal();
    },
    toggleUserModal() {
      return (this.editUserModal = !this.editUserModal);
    },
    showIOTModal(item) {
      this.selectedUser = { ...item.iotDevice };
      this.toggleIOTModal();
    },

    showVehicleModal(item) {
      this.selectedUser = { ...item };
      this.toggleVehicleModal();
    },
    toggleVehicleModal() {
      return (this.editVehicleModal = !this.editVehicleModal);
    },
  },
};
</script>

<style lang="sass" scoped>
.flex-boxes
  display: flex
  justify-content: space-around
  flex-wrap: wrap
  .box
    flex-basis: 15%
    margin: 0.5rem 0

    .v-card__title
      color: gray
      font-size: 1rem
      text-wrap : nowrap
    .v-card__text
      text-align : center

  .info-panel
    flex-basis: 25%
    margin: 0.5rem 0
    text-wrap: nowrap
    .v-card__text
      table
        width:100%
        font-size: 14px
      tr td:first-child
        color: gray
      tr td:last-child
        font-weight: 600

.collapse-btn
  display: flex
  justify-content: space-between
  align-items: center
</style>
